import { TypeVideoTeaser } from "@contentful/types";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  H4,
  P,
  SegmentClickTypes,
} from "@yardzen/ui";
import Image from "next/image";

export function VideoTeaser({
  videoTeaser,
  segmentFlow,
}: {
  videoTeaser: TypeVideoTeaser<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: string;
}) {
  return (
    <Dialog>
      <div className="border-system-grayscale-20 flex flex-col justify-center gap-12 rounded-lg border border-dashed bg-white p-8 md:flex-row ">
        <div className="content-center">
          <div className="relative h-36 w-36">
            <DialogTrigger asChild>
              <Image
                src={`https:${videoTeaser.fields.image?.fields.image?.fields.file?.url}`}
                alt={
                  videoTeaser.fields.image?.fields.image?.fields.description ??
                  ""
                }
                height={140}
                width={140}
                className={`object-cover`}
              />
            </DialogTrigger>
            <div className="bg-brand-primary-light absolute bottom-0 right-0 h-11 w-11 rounded-full">
              <FontAwesomeIcon
                icon={faPlay}
                className="ml-1 mt-3 h-5 w-5 text-white"
              />
            </div>
          </div>
        </div>
        <div className="text-left">
          <H4 className="text-brand-primary-dark text-3xl">
            {videoTeaser.fields.heading}
          </H4>
          <div>
            <P className="text-brand-primary-dark mt-4">
              {videoTeaser.fields.subheading?.content?.[0]?.content?.[0]
                ?.nodeType === "text"
                ? videoTeaser.fields.subheading.content[0].content[0].value
                : ""}
            </P>
          </div>
          <DialogTrigger asChild>
            <Button
              className="mt-4"
              variant="outline"
              tracking={{
                flow_name: segmentFlow,
                button_name: "videoTeaser",
                click_type: SegmentClickTypes.BUTTON,
                button_content: "Play video",
              }}
            >
              Play video
            </Button>
          </DialogTrigger>
          <DialogContent className="w-11/12 max-w-screen-lg rounded-lg border-0 p-0 md:w-4/6">
            <DialogClose className="bg-brand-primary-light absolute -top-10 right-0 z-20 -mt-1 h-10 w-10 rounded-full md:-right-10">
              <FontAwesomeIcon
                icon={faXmark}
                className="mt-0.5 h-4 w-4 text-white"
              />
              <span className="sr-only">Close</span>
            </DialogClose>
            {/* 56.25% is 16:9 aspect ratio */}
            <div className="relative z-10" style={{ paddingBottom: "56.25%" }}>
              <iframe
                width="560"
                height="315"
                className="absolute h-full w-full rounded-lg"
                src={`${videoTeaser.fields.videoUrl}?autoplay=1`}
                title={videoTeaser.fields.heading}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </DialogContent>
        </div>
      </div>
    </Dialog>
  );
}
